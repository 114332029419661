import { Component, OnInit, ViewChild } from "@angular/core";
import { EverythingService } from "../everything.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { SoundService } from "../sound.service";
import { Router } from "@angular/router";
import { slideLeft } from "../_animations/slide-left";
import { containerSlideUp } from "../_animations/slide-up";
import { TopBarComponent } from "../top-bar/top-bar.component";
import { QuizService } from "../services/quiz.service";
import { Quiz } from "../models/quiz";
import { ifTrue } from "codelyzer/util/function";
import { ShareService } from "../services/share.service";
import { pageTransition } from "../_animations/page-transition";
import { TrackService } from "../services/track.service";

declare let gtag: Function;

@Component({
  selector: "app-game-done",
  templateUrl: "./game-done.component.html",
  styleUrls: ["./game-done.component.scss"],
  host: { "[@pageTransition]": "" },
  animations: [pageTransition, containerSlideUp],
})
export class GameDoneComponent implements OnInit {
  public user = null;
  public leaderBoard = [];
  public meBoard = null;
  public stats: any = null;
  public loading = true;

  public quiz: Quiz = null;

  public segment = null;
  public segmentImage = null;

  @ViewChild(TopBarComponent, { static: false }) topBar: TopBarComponent;

  constructor(
    private et: EverythingService,
    private http: HttpClient,
    private ss: SoundService,
    private router: Router,
    private quizService: QuizService,
    private shareService: ShareService,
    public track: TrackService
  ) {}

  ngOnInit() {
    this.user = this.et.getUser();
    // this.ss.playWin();
    this.loading = true;

    this.quizService.getQuiz().subscribe((quiz) => {
      this.quiz = quiz;

      this.http.get(`/game`).subscribe(
        (game: any) => {
          this.stats = game;
          if (!game.game_done) {
            this.router.navigate(["/question"]);
          }

          this.segment = this.quiz.settings.resultSegments.find((segment) => {
            if (
              segment.from <= game.score &&
              segment.to >= game.score_percentage
            ) {
              return true;
            }
            return false;
          });

          if (this.segment) {
            this.segmentImage = null;

            switch (this.segment.name) {
              case "Strojevodja, ki pelje v drugo ligo":
                this.segmentImage = "segment4";
                break;
              case "Srečnež, ki se je rešil pred izpadom":
                this.segmentImage = "segment3";
                break;
              case "Trener za konferenčno ligo":
                this.segmentImage = "segment2";
                break;
              case "Osvajalec dvojne krone":
                this.segmentImage = "segment1";
                break;
            }

            this.segmentImage = `./assets/img/_nogomet_maj2023/${this.segmentImage}.png`;
          }

          if (this.quiz.settings.scoreBoard) {
            this.http
              .get(`${environment.api}/leader-board/15`)
              .subscribe((leaderBoard: any) => {
                this.loading = false;
                let meFound = false;
                this.leaderBoard = leaderBoard.filter((board) => {
                  if (meFound && board.user_id === this.user.id) {
                    // return false;
                  }
                  if (board.user_id === this.user.id) {
                    meFound = true;
                  }
                  return true;
                });
                if (leaderBoard.length > 0) {
                  this.meBoard = leaderBoard.find((board) => {
                    return (
                      board.user_id === this.user.id &&
                      board.score === this.stats.score
                    );
                  });
                  if (!this.meBoard) {
                    this.meBoard = leaderBoard.find((board) => {
                      return board.user_id === this.user.id;
                    });
                  }
                }
              });
          }
        },
        (error) => {
          this.router.navigate(["/"]);
        }
      );
    });

    gtag("event", "page_view", {
      page_title: "Igra zaključena (segment)",
      page_path: "/game-done",
    });
  }

  share(type: string) {
    this.track.gaTrackAction("share", "social_share", "segment");
    this.shareService.share(type);
  }
}
