import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EverythingService } from "../everything.service";
import { ActivatedRoute, Router } from "@angular/router";
import { animate, style, transition, trigger } from "@angular/animations";
import { environment } from "../../environments/environment";
import { slideLeft } from "../_animations/slide-left";
import { pageTransition } from "../_animations/page-transition";
import { TrackService } from "../services/track.service";

declare let gtag: Function;

@Component({
  selector: "app-user-finalize",
  templateUrl: "./user-finalize.component.html",
  styleUrls: ["./user-finalize.component.scss"],
  host: { "[@pageTransition]": "" },
  animations: [
    pageTransition,
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ transform: "translateY(0%) scale(0)", opacity: 0 }),
        animate(
          ".25s ease-in-out",
          style({ transform: "translateY(0%) scale(1)", opacity: 1 })
        ),
      ]),
      transition(":leave", [
        style({ transform: "translateY(0%) scale(1)", opacity: 1 }),
        animate(
          ".25s ease-in-out",
          style({ transform: "translateY(0%) scale(0)", opacity: 0 })
        ),
      ]),
    ]),
  ],
})
export class UserFinalizeComponent implements OnInit {
  public name: string = null;
  public email = null;
  public terms = false;
  public newsletter = false;

  public nameError: boolean | string = false;
  public emailError: boolean | string = false;
  public termsError: boolean | string = false;

  public formSent = false;
  public formError: boolean | string = false;

  public avatars = [
    "avocado-1",
    "avocado-2",
    "avocado-3",
    "blueberry-1",
    "blueberry-2",
    "blueberry-3",
    "egg-1",
    "egg-2",
    "egg-3",
    "tomato-1",
    "tomato-2",
    "tomato-3",
    //'sheep-1', 'sheep-2', 'sheep-3',
  ];

  public selectedAvatar = null;
  public avatarsModal = false;

  public user = null;
  public queryParams = null;

  constructor(
    private http: HttpClient,
    private et: EverythingService,
    private router: Router,
    private route: ActivatedRoute,
    public track: TrackService
  ) {}

  ngOnInit() {
    this.user = this.et.getUser();
    if (!this.user) {
      this.router.navigate(["/"]);
    }
    this.queryParams = this.route.snapshot.queryParams;

    gtag("event", "page_view", {
      page_title: "Prijava uporabnika",
      page_path: "/prijava",
    });
    //this.track.gaTrackAction('complete', 'page_view');
  }

  selectAvatar() {
    this.avatarsModal = true;
  }

  chooseAvatar(avatar) {
    this.selectedAvatar = avatar;
    this.avatarsModal = false;
  }

  sendForm() {
    let error = false;
    this.nameError = false;
    this.emailError = false;
    this.termsError = false;
    this.formError = false;
    this.avatarsModal = false;

    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!this.email || this.email === "") {
      error = true;
      this.emailError = "Vnesite vaš e-naslov";
    }

    if (!re.test(String(this.email).toLowerCase())) {
      error = true;
      this.emailError = "E-naslov ni ustrezen";
    }

    if (!this.terms) {
      error = true;
      this.termsError = "Strinjati se morate s pravili in pogoji.";
    }

    if (!error) {
      this.formSent = true;
      this.http
        .put(`${environment.api}/user`, {
          email: this.email,
          terms: this.terms,
          newsletter: this.newsletter,
        })
        .subscribe(
          (resp: any) => {
            this.formSent = false;
            this.et.setUser(resp);
            this.track.gaTrackAction("loged_user", "cta", "opt-in");
            if (this.newsletter) {
              this.track.gaTrackAction("newsletter");
            }
            if (resp.games_done >= 2) {
              //this.router.navigate(['/leaders']);
            } else {
              //this.router.navigate(['/ready']);
            }

            if (this.queryParams.next) {
              this.router.navigate([`/${this.queryParams.next}`]);
            } else {
              this.router.navigate(["/game-done"]);
            }
          },
          (errorr) => {
            this.formSent = false;

            if (errorr.error && errorr.error.error) {
              console.log(errorr.error.error.errorInfo[2]);
              switch (errorr.error.error) {
                case "max_games":
                  this.router.navigate(["/game-done"]);
                  break;
                case "name_exists":
                  this.formError = "Uporabnik s tem imenom že obstaja";

                  break;

                default:
                  this.formError = "Uporabnik s tem imenom že obstaja";
                  break;
              }
            } else {
              this.formError = "Prišlo je do napake";
            }
          }
        );
    }
  }
}
